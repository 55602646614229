
/* ///////////////////

Variables 

////////////////////*/

@font-face {
  font-family: rubberstamp;
  src: url(../public/assets/MailartRubberstamp-Regular-Oblique/MailartRubberstamp-Regular.otf);
}

:root {
  --shadow-color: 0deg 0% 58%;
  --shadow-elevation-low:
    0.4px 1px 1.4px hsl(var(--shadow-color) / 0.23),
    0.7px 1.8px 2.5px -0.7px hsl(var(--shadow-color) / 0.34),
    1.5px 4.3px 5.8px -1.4px hsl(var(--shadow-color) / 0.45);
  --shadow-elevation-medium:
    0.4px 1px 1.4px hsl(var(--shadow-color) / 0.19),
    1.2px 3.4px 4.6px -0.4px hsl(var(--shadow-color) / 0.25),
    2.3px 6.3px 8.6px -0.7px hsl(var(--shadow-color) / 0.3),
    4.2px 11.6px 15.8px -1.1px hsl(var(--shadow-color) / 0.36),
    7.7px 21.4px 29.2px -1.4px hsl(var(--shadow-color) / 0.41);
  --shadow-elevation-high:
    0.4px 1px 1.4px hsl(var(--shadow-color) / 0.18),
    2.6px 7.1px 9.7px -0.2px hsl(var(--shadow-color) / 0.2),
    4.6px 12.6px 17.2px -0.3px hsl(var(--shadow-color) / 0.23),
    6.7px 18.6px 25.4px -0.5px hsl(var(--shadow-color) / 0.26),
    9.3px 25.8px 35.2px -0.6px hsl(var(--shadow-color) / 0.28),
    12.7px 35.2px 48px -0.8px hsl(var(--shadow-color) / 0.31),
    17.3px 47.9px 65.3px -1px hsl(var(--shadow-color) / 0.33),
    23.3px 64.6px 88.1px -1.1px hsl(var(--shadow-color) / 0.36),
    31.1px 86.3px 117.6px -1.3px hsl(var(--shadow-color) / 0.38),
    41.1px 114px 155.4px -1.4px hsl(var(--shadow-color) / 0.41);
}


/* ///////////////////

Normalize

////////////////////*/

body {
  margin: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  background-color: hsl(349.09,9.73%,94.31%);
}



/* ///////////////////

Basic Elements

////////////////////*/
a {
  color: #918E9B;
}

h1 {
  font-family: 'Inter', sans-serif;
  font-weight: 800;
}

hr {
  border: 1px solid #f5f5f5;
}

img {
  height: 310px;
  width: 240px;
  object-fit: cover;
  margin: 0px 10px 0px 0px;
  border-radius: 15px;
}

main {
  overflow: hidden; 
}

p {
  margin: 0;
}

/* ///////////////////

Classes

////////////////////*/
.alaska {
  height: auto;
  position: absolute;
  -webkit-transform: translate(-89%,424px) rotate(350deg);
  transform: translate(-140%,124px) rotate(350deg);
  width: 29%;
}

.all-caps {
  text-transform: uppercase;
}

.bold {
  font-weight: 800;
}

.card--container {
  display: flex;
  flex-direction: row;
  /* margin-bottom: 37px; */
  border-bottom: solid 2px #f5f5f5;
  padding: 30px 0;
}

.card--info-container {
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  justify-content: flex-start;
}

.card--map-pin {
  height: 10px; 
  width: 10px; 
  color: red;
}

.card--pin-container {

}

.card--reverse-flow {
  display: flex;
  flex-direction: column;
}

.char6 {
  transform: rotate(96deg)
}

.char7 {
  transform: rotate(116deg)
}

.char8 {
  transform: rotate(136deg)
}

.char9 {
  transform: rotate(156deg)
}

.char10 {
  transform: rotate(176deg)
}

.char11 {
  transform: rotate(196deg)
}

.char12 {
  transform: rotate(0deg)
}

.char13 {
  transform: rotate(20deg)
}

.char14 {
  transform: rotate(40deg)
}

.char15 {
  transform: rotate(60deg)
}

.char16 {
  transform: rotate(80deg)
}

.char17 {
  transform: rotate(100deg)
}

.char18 {
  transform: rotate(120deg)
}

.chinese-coin {
  transform: translate(24vh, 3vh);
  width: 18%;
  height: 100%;
}

.definition-container {
  color: #c4745a;
  font-family: rubberstamp;
  font-size: 1.25rem;
  margin-left: 9em;
  margin-top: 6em;
  width: 30%;
}

.definition-container > p {
  margin-bottom: .5em;
}

.emb {
  height: auto;
  position: absolute;
  -webkit-transform: translate(89%,127px) rotate(7deg);
  transform: translate(29%,102px) rotate(348deg);
  width: 15%;
  z-index: 40;
}

.footer-image {
  padding-left: 6em;
}

.gadabout {
  color: #c4745a;
  float: left;
  margin: 1em 2em 0 0;
  shape-margin: 1.125rem;
  shape-outside: circle(39%);
  -webkit-transform: rotate(354deg);
  transform: rotate(354deg);
}

.iraqi-flag {
  width: 20%;
  height: auto;
  transform: translate(-71px, -46px) rotate(6deg);
}

.lao-tzu {
  display: inline-block;
  margin-top: 1em;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.parallax--first {
  background-image: url('../public/assets/spain_projects_and_finished_products.jpg');
  width: 100vw;
  min-height: 90vh;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}


.photo-credit {
  font-weight: 800;
  margin-top: 1em;
  color: #f55a5a;
}

.not-parallax--first {
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 90vh;
  width: 100vw;
  padding: 6em;
  position: relative;
}

.parallax--second {
  background-image: url('../public/assets/GOAT.jpg');
  width: 100vw;
  min-height: 90vh;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.root {
  padding: 0px 40px;
}

.root--top-bar {
  height: 55px;
  background-color: #F55A5A;
}

.root--container {
  width: 550px;
  background-color: #FFFFFF;
  margin: auto;
  box-shadow: var(--shadow-elevation-medium);
  transition: 400ms ease-in;
  transform: translate(144px, -30px) rotate(8deg);
  position: absolute;
  top: 9vh;
  left: 41vw;
}

.root--container:hover {
  box-shadow: var(--shadow-elevation-high);
  transition: 400ms ease-in;
}

.salvador {
  width: 32%;
  height: auto;
  transform: translate(10px, 10px);
  position: relative;
  z-index: -2;
}

.scan {
  min-width: 30vw;
  height: 100%;
}

.scan-container {
  min-width: 100vw;
  transform: translateY(-46vh);
}

.small-text {
  font-size: 10px;
  margin: 0 0 10px 0;
}

.special-p {
  margin-left: 7em;
}

.terrorist-permit {
  width: 28%;
  height: auto;
  transform: translate(-188px, 10px);
  position: relative;
  z-index: -2;
}

.text-alaska {
  font-family: 'rubberstamp';
  font-size: 6rem;
  color: hsl(349.44,58.14%,57.84%);
  display: inline-block;
  width: 36%;
  text-align: end;
}

.title {
  color: #587b99;
  font-family: rubberstamp;
  font-size: 10.5rem;
  position: relative;
  text-align: left;
  z-index: -10;
  transform: rotate(317deg) translate(36%, -44vw);
}

.title1 {
  font-family: 'rubberstamp';
  font-size: 10.5rem;
  text-align: left;
  color: #587b99;
  position: relative;
  z-index: -10;
  height: 60vh;
  -webkit-transform: translate(23.75%,53vh);
  transform: translate(26.75%, 46vh);
}

.title span,
.title1 span {
  position: absolute;
  transform-origin: bottom center;
  left: 0; 
  top: 0;
  height: 550px;
  z-index: 1;
}



.wide-kearning {
  letter-spacing: .17em;
}

.white-bible {
    position: absolute;
    width: 29%;
    height: auto;
    transform: translate(10px 10px);
    transform: translate(105%, -4%) rotate(350deg);
}
/* ///////////////////

Media Queries

////////////////////*/